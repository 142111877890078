import React from 'react';
import FormControl from '@mui/material/FormControl';
import TextField from '@mui/material/TextField';
import {Stack, InputLabel, InputAdornment, IconButton ,Input} from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import services from '../services';
import sessionManager from '../sessionManager';
import { useNavigate } from "react-router-dom";
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import FormHelperText from '@mui/material/FormHelperText';
import '../css/login.css'
import classNames from 'classnames';
import logo from '../img/posgon-12.png'

const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
  });

const Giris=()=>{
    const navigate = useNavigate()
    const [showPassword, setShowPassword] = React.useState(false);
    const [kullaniciAdi, setKullaniciAdi] = React.useState({value:'', hata:false});
    const [sifre, setsifre] = React.useState({value:'', hata:false});
    const [loading, setLoading] = React.useState(false);
    const [err, setErr] = React.useState({message:'', open:false});
    
    const { vertical, horizontal} = {vertical:'top', horizontal:'right'}
    const handleClickShowPassword = () => setShowPassword((show) => !show);
    
    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };    
    
    const kaydet=()=>{
        let durum= true;
        if(!kullaniciAdi.value){
            setKullaniciAdi({...kullaniciAdi, hata:true});
            durum=false;
        }        
        if(!sifre.value){
            setsifre({...sifre, hata:true});
            durum=false;
        }
        if(durum){
            setLoading(true)
            const data={
                kullaniciAdi:kullaniciAdi.value,
                sifre:sifre.value
            }

            services.loginAxios('Giris/Giris', data).then((res)=>{
                if(res.data.authenticateResult){
                    //console.log(res.data.authToken)
                    sessionManager.setUserSession(res.data.authToken, res.data.accessTokenExpireDate)
                    navigate('/')
                }else{
                    //toast.error('Kullanıcı adı ya da şifre hatalı.', {duration:3500})
                    setErr({message:'Kullanıcı adı ya da şifre hatalı.', open:true})
                }
                setLoading(false)
            }).catch((err)=>{
                //toast.error('Bir hata oluştu.', {duration:3000})
                setErr({message:'Bir hata oluştu.', open:true})
                setLoading(false)
            })
        }
    }

    return (
        <>
            <Snackbar open={err.open} autoHideDuration={3500} onClose={()=>setErr({...err, open:false})} 
             anchorOrigin={{ vertical, horizontal }}>
                <Alert severity="error" sx={{ width: '100%' }}>
                {err.message}
                </Alert>
            </Snackbar>
            <div className='px-5 d-flex' style={{height:"100vh", backgroundColor:"#f8fafb"}}>
                <div className='my-auto w-100'>
                    <div className='row justify-content-center'>
                        <div className='col-lg-5 col-md-6 col-sm-7 kutu2'>
                            <div className='rounded-4 p-4 py-5 kutu'>
                                <div className='justify-content-center d-flex mb-3'>
                                    {/* <label className='text-center fs-2 oturumAc'>LOGO</label> */}
                                    <img src={logo} className='login-logo'/>
                                </div>
                                <Stack spacing={3}>
                                    <label className='text-center fs-2 mb-5 oturumAc'>Giriş Yap</label>
                                    <TextField
                                    error={kullaniciAdi.hata}
                                    value={kullaniciAdi.value}
                                    onChange={(event)=>{
                                        setKullaniciAdi({value:event.target.value, hata:event.target.value? false: true})}}
                                    id="outlined-error"
                                    label="Kullanıcı adı"
                                    variant='standard'
                                    color='success'
                                    helperText={kullaniciAdi.hata ? 'Doldurulması zorunlu alan.' : ''}
                                    />
                                    <FormControl sx={{ m: 1 }} variant="standard" color='success'>
                                        <InputLabel htmlFor="standard-adornment-password" error={sifre.hata}>Şifre</InputLabel>
                                        <Input
                                            onKeyDown={(e)=> {if(e.key === 'Enter'){kaydet()}}}
                                            error={sifre.hata}
                                            value={sifre.value}
                                            onChange={(event)=>{
                                                setsifre({value:event.target.value, hata:event.target.value? false: true})}}
                                            id="standard-adornment-password"
                                            type={showPassword ? 'text' : 'password'}
                                            endAdornment={
                                            <InputAdornment position="end">
                                                <IconButton
                                                aria-label="toggle password visibility"
                                                onClick={handleClickShowPassword}
                                                onMouseDown={handleMouseDownPassword}>
                                                {showPassword ? <VisibilityOff /> : <Visibility />}
                                                </IconButton>
                                            </InputAdornment>
                                            }
                                            label="Şifre"
                                            color='success'/>
                                            <FormHelperText 
                                                error={sifre.hata}>
                                                    {sifre.hata ? "Doldurulması zorunlu alan":""}
                                            </FormHelperText>
                                    </FormControl>
                                    <LoadingButton loading={loading} 
                                    className={classNames('login-btn', {"opacity-75" : loading})} 
                                    size='large' variant="contained" onClick={kaydet}>
                                        Giriş
                                    </LoadingButton>
                                </Stack>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default Giris;