import React from 'react'
import SessionManager from './sessionManager'
import { Navigate, redirect } from 'react-router-dom'

function AuthenticationControl({children}){
    const token = SessionManager.getToken()
    const date = new Date()
    if(!token.token || token.date.replace("-",".") <= date.toLocaleString('tr-TR').replace(".","-"))
        return <Navigate to="/login" />
    
    return children 
}

export default AuthenticationControl