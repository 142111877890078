import React from "react";
import {InputLabel, MenuItem, FormControl, Select, 
    Button, FormHelperText, TextField, Stepper, 
    Step, StepLabel, StepContent, Box} from '@mui/material';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import LoadingButton from '@mui/lab/LoadingButton';
import SaveIcon from '@mui/icons-material/Save';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import { TimeField } from '@mui/x-date-pickers/TimeField';
import dayjs from 'dayjs';
import { toast } from "react-hot-toast";
import classNames from "classnames";
import request from "../services";
import Autocomplete from '@mui/material/Autocomplete';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';


const HesapEkle = ({setModal, musteriId, hesapId, yeniHesap})=>{
    const [adres, setAdres] = React.useState({value:'', hata:false})
    const [kullaniciAdi, setKullaniciAdi] = React.useState('')
    const [sifre, setSifre] = React.useState({value:'', hata:false})
    const [sunucu, setSunucu] = React.useState({value:'', hata:false})
    const [port, setPort] = React.useState({value:'', hata:false})
    const [guvenlik, setGuvenlik] = React.useState({value:'', hata:false})
    const [imapSunucu, setImapSunucu] = React.useState({value:'', hata:false})
    const [imapPort, setImapPort] = React.useState({value:'', hata:false})
    const [imapGuvenlik, setImapGuvenlik] = React.useState({value:'', hata:false})
    const [ad, setAd] = React.useState({value:'', hata:false})
    const [fromName, setFromName] = React.useState({value:'', hata:false})
    const [firstTime, setFirstTime] = React.useState({value:dayjs('2022-04-17T09:00'), hata:false})
    const [lastTime, setLastTime] = React.useState({value:dayjs('2022-04-17T18:00'), hata:false})
    const [adet, setAdet] = React.useState({value:100, hata:false})
    const [activeStep, setActiveStep] = React.useState(0);
    const [kontrol, setKontrol] = React.useState(false);
    const [imapKontrol, setImapKontrol] = React.useState(false);
    const [kaydetLoading, setKaydetLoading] = React.useState(false);
    const [disabled, setDisabled] = React.useState(false);
    const [gonGunleri, setGonGunleri] = React.useState([]);
    const [gpk, setGPK] = React.useState(false);
    const [comp, setComp] = React.useState(false);
    
    const handleNext = () => {
        setActiveStep((prevActiveStep) => gpk ? prevActiveStep + 1 : 2);
        
        if(gpk && activeStep === 1)
        setComp(true);
    };
    
    const handleBack = () => {
        setActiveStep((prevActiveStep) => gpk ? prevActiveStep - 1 : 0);

        if(gpk && activeStep === 2)
            setComp(false)
      };
    
    const hesapSina = ()=>{
        let durum =false;

        if(!adres.value){
            durum=true;
            setAdres({...adres, hata:true})
        }
        if(!sifre.value){
            setSifre({...sifre, hata:true})
            durum=true;
        }
        if(!sunucu.value){
            setSunucu({...sunucu, hata:true})
            durum=true;
        }
        if(!port.value){
            setPort({...port, hata:true})
            durum=true;
        }
        if(!guvenlik.value){
            setGuvenlik({...guvenlik, hata:true})
            durum=true;
        }

        if(!durum){
            setKontrol(true);
            const toastId = toast.loading('Hesap bilgileri kontrol ediliyor lütfen bekleyiniz.')
            
            const data={
                kullaniciAdi: kullaniciAdi || adres.value,
                sifre: sifre.value,
                smtpSunucu: sunucu.value,
                smtpPort: parseInt(port.value),
                smtpGuvenlik: guvenlik.value
            }

            request.postAxios('Hesap/HesapKontrol', data).then((res)=>{
                handleNext();
                setKontrol(false)
                toast.success("Hesap bilgileri doğrulandı.", 
                {id:toastId, duration:2500})
            }).catch((err)=>{
                if(err?.response?.status === 401){
                    window.location.href = '/login'
                    return;
                }
                setKontrol(false)
                toast.error(typeof err?.response?.data =='string' ? err.response.data || 
                "Bir hata oluştu.":"Bir hata oluştu", 
                {id:toastId, duration:3000})
            })
        }
    }

    const imapSina=()=>{
        let durum = true

        if(!imapSunucu.value){
            setImapSunucu({...imapSunucu, hata:true})
            durum=false
        }
        if(!imapPort.value){
            setImapPort({...imapPort, hata:true})
            durum=false
        }
        if(!imapGuvenlik.value){
            setImapGuvenlik({...imapGuvenlik, hata:true})
            durum=false
        }

        if(durum){
            setImapKontrol(true)
            const toastId = toast.loading('Hesap bilgileri kontrol ediliyor lütfen bekleyiniz.')
            
            const data={
                kullaniciAdi: kullaniciAdi || adres.value,
                sifre: sifre.value,
                imapSunucu: imapSunucu.value,
                imapPort: parseInt(imapPort.value),
                imapGuvenlik: imapGuvenlik.value
            }

            request.postAxios('Hesap/ImapKontrol', data).then((res)=>{
                handleNext();
                setImapKontrol(false)
                toast.success("Hesap bilgileri doğrulandı.", 
                {id:toastId, duration:2500})
            }).catch((err)=>{
                if(err?.response?.status === 401){
                    window.location.href = '/login'
                    return;
                }
                setImapKontrol(false)
                toast.error(typeof err?.response?.data =='string' ? err.response.data || 
                "Bir hata oluştu.":"Bir hata oluştu", 
                {id:toastId, duration:3000})
            })
        }
    }

    const kaydet = ()=>{
        let durum=true;

        if(!ad.value){
            setAd({...ad, hata:true})
            durum=false
        }
        if(!fromName.value){
            setFromName({...fromName, hata:true})
            durum=false
        }
        if(!firstTime.value){
            setFirstTime({...firstTime, hata:true})
            durum=false
        }
        if(!lastTime.value){
            setLastTime({...lastTime, hata:true})
            durum=false
        }
        if(!adet.value){
            setAdet({...adet, hata:true})
            durum=false
        }

        if(durum){
            const is=firstTime.value.$H
            const id=firstTime.value.$m
            const ss=lastTime.value.$H
            const sd=lastTime.value.$m
            
            let ilk= ''
            ilk += is < 10 ? '0' +is:is
            let x = ilk
            ilk += ':' 
            ilk += id < 10 ? '0' +id:id
            x += id < 10 ? '0' +id:id
            let son= ''
            son += ss < 10 ? '0' +ss:ss
            let y = son
            son += ':' 
            son += sd < 10 ? '0' +sd:sd
            y += sd < 10 ? '0' +sd:sd
            
            if(x > y){
                toast.error('Başlangıç saati bitiş saatinden büyük olamaz.', {duration:3000})
                return;
            }
            if(x === y){
                toast.error('Başlangıç saati bitiş saatiyle aynı olamaz.', {duration:3000})
                return;
            }

            setKaydetLoading(true);
            const toastId = toast.loading('Kayıt tamamlanıyor lütfen bekleyiniz.')
            let gg = ''
            gonGunleri?.map((item)=>{
                gg += item.value +','
            })

            const data={
                id:parseInt(hesapId) || null,
                musteriId:musteriId || null,
                ad:ad.value,
                fromName:fromName.value,
                postaAdresi:adres.value,
                ilkSaat:'0.'+ ilk +':00',
                sonSaat:'0.'+ son +':00',
                gonderimAdet:parseInt(adet.value),
                kullaniciAdi: kullaniciAdi,
                sifre: sifre.value,
                smtpSunucu: sunucu.value,
                smtpPort: parseInt(port.value),
                smtpGuvenlik: guvenlik.value,
                postaKaydet:gpk,
                imapSunucu: imapSunucu.value,
                imapPort: parseInt(imapPort.value) || 0,
                imapGuvenlik: imapGuvenlik.value,
                gonderimGun:gg ? gg.substring(0, gg.length - 1): ''
            }
            
            request.postAxios('Hesap/InsertUpdateHesap', data).then((res)=>{
                if(musteriId)
                {
                    yeniHesap()
                    setModal(false)
                    toast.success(ad.value + " Hesaplar arasına eklendi.", 
                    {id:toastId, duration:2500})
                }else{
                    toast.success("Kayıt tamamlandı.", 
                    {id:toastId, duration:3000})
                }
                setKaydetLoading(false)
            }).catch((err)=>{
                if(err?.response?.status === 401){
                    window.location.href = '/login'
                    return;
                }
                toast.error("Kayıt tamamlanırken bir hata oluştu.", 
                    {id:toastId, duration:3000})
                setKaydetLoading(false)
            })
        }
    }

    React.useEffect(()=>{
        if(hesapId){
            setDisabled(true)
            setActiveStep(2)
            request.getAxios('Hesap/GetHesap?hesapId=' + hesapId).then((res)=>{
                setAdres({...adres, value:res.data.postaAdresi})
                setKullaniciAdi(res.data.kullaniciAdi)
                setSifre({...sifre, value:res.data.sifre})
                setSunucu({...sunucu, value:res.data.smtpSunucu})
                setPort({...port, value:res.data.smtpPort})
                setGuvenlik({...guvenlik, value:res.data.smtpGuvenlik})
                setGPK(res.data.postaKaydet || false)
                setComp(res.data.postaKaydet || false)
                setImapSunucu({...imapSunucu, value:res.data.imapSunucu})
                setImapPort({...imapPort, value:res.data.imapPort || ''})
                setImapGuvenlik({...imapGuvenlik, value:res.data.imapGuvenlik || ''})
                setAd({...ad, value:res.data.ad})
                setFromName({...fromName, value:res.data.fromName})
                setFirstTime({...firstTime, value:dayjs('2022-04-17T'+ res.data.ilkSaat.substring(0,5))})
                setLastTime({...lastTime, value:dayjs('2022-04-17T'+ res.data.sonSaat.substring(0,5))})
                setAdet({...adet, value:res.data.gonderimAdet})
                if(res.data.gonderimGun)
                    setGonGunleri([...Gunler.filter((item)=> res.data.gonderimGun.includes(item.value))])
            }).catch((err)=>{
                if(err?.response?.status === 401){
                    window.location.href = '/login'
                    return;
                }
                toast.error('Hesap ayarları yüklenirken bir hata oluştu.', {duration:3000})
            })
        }else{
            setGonGunleri([...Gunler.filter((item)=> item.value !== 6 && item.value !== 0)])
        }
    },[])

return(
    <Stepper activeStep={activeStep} orientation="vertical">
    <Step>
        <StepLabel>
        Hesap Ayarları
        </StepLabel>
        <StepContent>
            <div className="row mt-3">
                <div className="col-md-4 mb-2">
                    <TextField
                    disabled={disabled}
                    required
                    error={adres.hata}
                    onChange={(event)=>{
                        setAdres({value:event.target.value, hata:event.target.value? false: true})} }
                    margin="dense"
                    id="adres"
                    value={adres.value}
                    label="E-posta Adresi"
                    type="mail"
                    fullWidth
                    size="small"
                    helperText={adres.hata ? 'Doldurulması zorunlu alan.' : ''}
                    />
                </div>
                <div className="col-md-4 mb-2">
                    <TextField
                    disabled={disabled}
                        margin="dense"
                        id="kullaniciAdiSmtp"
                        label="Kullanıcı Adı"
                        autoComplete="off"
                        type="text"
                        fullWidth
                        size="small"
                        onChange={(event)=> setKullaniciAdi(event.target.value)}
                    />
                </div>
                <div className="col-md-4 mb-2">
                    <TextField
                        disabled={disabled}
                        required
                        error={sifre.hata}
                        onChange={(event)=>{
                            setSifre({value:event.target.value, hata:event.target.value? false: true})
                        }}
                        margin="dense"
                        id="sifreSmtp"
                        value={sifre.value}
                        label="Şifre"
                        autoComplete="new-password"
                        type="password"
                        fullWidth
                        size="small"
                        helperText={sifre.hata ? 'Doldurulması zorunlu alan.' : ''}
                    />
                </div>
            </div>
            <div className="row">
                <div className="col-md-4 mb-2">
                    <TextField
                        disabled={disabled}
                        required
                        error={sunucu.hata}
                        onChange={(event)=>{
                            setSunucu({value:event.target.value, hata:event.target.value? false: true})
                        }}
                        margin="dense"
                        id="sunucu"
                        value={sunucu.value}
                        label="SMTP Sunucusu"
                        type="text"
                        fullWidth
                        size="small"
                        helperText={sunucu.hata ? 'Doldurulması zorunlu alan.' : ''}
                    />
                </div>
                <div className="col-md-4 mb-2">
                    <TextField
                        disabled={disabled}
                        required
                        error={port.hata}
                        onChange={(event)=>{
                            if(event.target.value > 0 && event.target.value < 65535)
                            setPort({value:event.target.value, hata:event.target.value? false: true})
                        }}
                        margin="dense"
                        id="port"
                        value={port.value}
                        label="SMTP Portu"
                        type="number"
                        fullWidth
                        size="small"
                        helperText={port.hata ? 'Doldurulması zorunlu alan.' : ''}
                    />
                </div>
                <div className="col-md-4 mb-2">
                    <FormControl margin="dense" size="small" error={guvenlik.hata} fullWidth required>
                        <InputLabel id="standard-label-g" disabled={disabled}>SMTP Güvenlik</InputLabel>
                        <Select
                        disabled={disabled}
                        labelId="standard-label-g"
                        id="smtpGuvenlik"
                        value={guvenlik.value}
                        onChange={(event)=> 
                            setGuvenlik({value:event.target.value, hata:event.target.value? false: true})}
                        label="SMTP Güvenlik"
                        >
                            <MenuItem value={4}>Yok</MenuItem>
                            <MenuItem value={1}>Otomatik</MenuItem>
                            <MenuItem value={2}>SSL</MenuItem>
                            <MenuItem value={3}>TLS</MenuItem>
                        </Select>
                        <FormHelperText>{guvenlik.hata ? 'Doldurulması zorunlu alan.': ''}</FormHelperText>
                    </FormControl>
                </div>
            </div>
            <div className="row">
                <div className="col-12">
                    <FormControlLabel className="ms-0"
                    control={
                        <Checkbox className="p-1" size="small" checked={gpk} onChange={()=>{setGPK(!gpk)}}/>
                    } 
                    label="Gönderilen postaları kaydet"/>
                </div>
            </div>
        <Box>
            <div className="d-flex justify-content-end">
            <Button
                disabled={kontrol}
                onClick={()=>{setModal(false); }}
                color="error"
                className={classNames({'d-none': hesapId? true: false}, 'me-1')}
            >
                İptal
            </Button>
            <Button
                onClick={()=>{setDisabled(false); }}
                className={classNames({'d-none': hesapId? false: true}, 'me-1')}
            >
                Düzenle
            </Button>
            <LoadingButton
            loading={kontrol}
            loadingPosition="end"
            endIcon={<NavigateNextIcon />}
            variant="outlined"
            onClick={()=>{hesapId && disabled ?  handleNext() : hesapSina()}}
            >İleri</LoadingButton>
            </div>
        </Box>
        </StepContent>
    </Step>
    <Step completed={comp}>
        <StepLabel>
            IMAP Ayarları
        </StepLabel>
        <StepContent>
        <div className="row mb-3">
                <div className="col-md-4 mb-2">
                    <TextField
                        required
                        error={imapSunucu.hata}
                        onChange={(event)=>{
                            setImapSunucu({value:event.target.value, hata:event.target.value? false: true})
                        }}
                        margin="dense"
                        id="imapSunucu"
                        value={imapSunucu.value}
                        label="IMAP Sunucusu"
                        type="text"
                        fullWidth
                        size="small"
                        helperText={imapSunucu.hata ? 'Doldurulması zorunlu alan.' : ''}
                    />
                </div>
                <div className="col-md-4 mb-2">
                    <TextField
                        required
                        error={imapPort.hata}
                        onChange={(event)=>{
                            if(!(event.target.value) || (event.target.value > 0 && event.target.value < 65535))
                            setImapPort({value:event.target.value, hata:event.target.value? false: true})
                        }}
                        margin="dense"
                        id="imapPort"
                        value={imapPort.value}
                        label="IMAP Portu"
                        type="number"
                        fullWidth
                        size="small"
                        helperText={imapPort.hata ? 'Doldurulması zorunlu alan.' : ''}
                    />
                </div>
                <div className="col-md-4 mb-2">
                    <FormControl margin="dense" size="small" error={imapGuvenlik.hata} fullWidth required>
                        <InputLabel id="standard-label">IMAP Güvenlik</InputLabel>
                        <Select
                        labelId="standard-label"
                        id="imapGuvenlik"
                        value={imapGuvenlik.value}
                        onChange={(event)=> 
                            setImapGuvenlik({value:event.target.value, hata:event.target.value? false: true})}
                        label="IMAP Güvenlik"
                        >
                            <MenuItem value={4}>Yok</MenuItem>
                            <MenuItem value={1}>Otomatik</MenuItem>
                            <MenuItem value={2}>SSL</MenuItem>
                            <MenuItem value={3}>TLS</MenuItem>
                        </Select>
                        <FormHelperText>{imapGuvenlik.hata ? 'Doldurulması zorunlu alan.': ''}</FormHelperText>
                    </FormControl>
                </div>
            </div>
            <Box>
            <div className="d-flex justify-content-end">
            <Button
                disabled={imapKontrol}
                onClick={()=>{setModal(false); }}
                color="error"
                className={classNames({'d-none': hesapId? true: false}, 'me-1')}
            >
                İptal
            </Button>
            <Button
                disabled={imapKontrol}
                onClick={handleBack}
                sx={{mr: 1 }}
            >
                Geri
            </Button>
            <LoadingButton
            loading={imapKontrol}
            loadingPosition="end"
            endIcon={<NavigateNextIcon />}
            variant="outlined"
            onClick={()=>{imapSina()}}
            >İleri</LoadingButton>
            </div>
        </Box>
        </StepContent>
    </Step>
    <Step>
        <StepLabel>
        Genel Ayarlar
        </StepLabel>
        <StepContent>
            <div className="row mt-3">
                <div className="col-md-6 mb-2">
                    <TextField
                        required
                        error={ad.hata}
                        onChange={(event)=>{
                            setAd({value:event.target.value, hata:event.target.value? false: true})
                        } }
                        margin="dense"
                        id="ad"
                        value={ad.value}
                        label="Hesap Adı"
                        type="text"
                        fullWidth
                        size="small"
                        helperText={ad.hata ? 'Doldurulması zorunlu alan.' : ''}
                    />
                </div>
                <div className="col-md-6 mb-2">
                    <TextField
                        required
                        error={fromName.hata}
                        onChange={(event)=>{
                            setFromName({value:event.target.value, hata:event.target.value? false: true})}}
                        margin="dense"
                        id="fromName"
                        value={fromName.value}
                        label="İleti Gönderilirken Kullanılacak İsim"
                        type="text"
                        fullWidth
                        size="small"
                        helperText={fromName.hata ? 'Doldurulması zorunlu alan.' : ''}
                    />
                </div>
            </div>
            <div className="row mb-3">
                <div className="col-md-6 mb-2">
                    <LocalizationProvider dateAdapter={AdapterDayjs} size="small">
                        <div className="d-sm-flex gap-4">
                            <TimeField required margin="dense" 
                                label="Başlangıç Saati" fullWidth size="small"
                                ampm={false}
                                error={firstTime.hata}
                                value={firstTime.value}
                                onChange={(event)=> 
                                    {setFirstTime({hata:event?.$m || event?.$m ===0 ? false:true, value:event})}}
                                helperText={firstTime.hata ? 'Doldurulması zorunlu alan.' : ''}/>
                            <TimeField required margin="dense" 
                                label="Bitiş Saati" fullWidth size="small"
                                ampm={false}
                                error={lastTime.hata}
                                value={lastTime.value}
                                onChange={(event)=> 
                                    {setLastTime({...lastTime, value:event})}}
                                helperText={lastTime.hata ? 'Doldurulması zorunlu alan.' : ''}/>
                        </div>
                    </LocalizationProvider>
                </div>
                <div className="col-md-6 mb-2">
                <TextField
                        required
                        error={adet.hata}
                        onChange={(event)=>{
                            if(!(event.target.value) || event.target.value > 0 && event.target.value < 101)
                            setAdet({value:event.target.value,hata:event.target.value? false: true})}}
                        margin="dense"
                        id="adet"
                        value={adet.value}
                        label="Gönderim Adeti"
                        type="number"
                        fullWidth
                        size="small"
                        helperText={adet.hata ? 'Doldurulması zorunlu alan.' : ''}
                    />
                </div>
            </div>
            <div className="row mb-3">
                <div className="col-12">
                    <Autocomplete
                        multiple
                        id="tags-outlined"
                        options={Gunler}
                        onChange={(event, value)=>setGonGunleri(value)}
                        getOptionLabel={(option) => option.title}
                        value={gonGunleri}
                        filterSelectedOptions
                        renderInput={(params) => (
                            <TextField
                            {...params}
                            label="Posta Gönderilecek Günler"
                            size="normal"
                        />
                        )}
                    />
                </div>
            </div>
        <Box sx={{ mb: 2 }}>
            <div className="d-flex justify-content-end">
            <Button
                disabled={kaydetLoading}
                onClick={()=>{setModal(false); }}
                color="error"
                className={classNames('me-1', {'d-none': hesapId ? true:false})}
            >
                İptal
            </Button>
            <Button
                disabled={kaydetLoading}
                onClick={handleBack}
                sx={{mr: 1 }}
            >
                Geri
            </Button>
            <LoadingButton
            loading={kaydetLoading}
            loadingPosition="end"
            endIcon={<SaveIcon />}
            variant="outlined"
            onClick={kaydet}
            >Kaydet</LoadingButton>
            </div>
        </Box>
        </StepContent>
    </Step>
</Stepper>
)}

const Gunler=[
    {title:'Pazartesi', value:1},
    {title:'Salı', value:2},
    {title:'Çarşamba', value:3},
    {title:'Perşembe', value:4},
    {title:'Cuma', value:5},
    {title:'Cumartesi', value:6},
    {title:'Pazar', value:0},
]

export default HesapEkle;