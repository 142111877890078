import {Outlet} from "react-router-dom";
import toast, { Toaster } from "react-hot-toast";
import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import Tooltip from '@mui/material/Tooltip';
import LogoutIcon from '@mui/icons-material/Logout';
import sessionManager from "../sessionManager";
import { useNavigate } from "react-router-dom";
import Avatar from '@mui/material/Avatar';
import Dialog from '@mui/material/Dialog';
import TextField from '@mui/material/TextField';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import CircularProgress from '@mui/material/CircularProgress';
import Backdrop from '@mui/material/Backdrop';
import Button from '@mui/material/Button';
import LoadingButton from '@mui/lab/LoadingButton';
import PersonIcon from '@mui/icons-material/Person';
import services from "../services";
import logo from '../img/posgon-12.png';

function ResponsiveAppBar() {
  const navigate = useNavigate()
  const [sdModal, setSdModal] = React.useState(false)
  const [backdrop, setBackdrop] = React.useState(false)
  const [loading, setLoading] = React.useState(false)
  const [kullaniciAdi, setKullaniciAdi] = React.useState({value:'', hata:false, mesaj:''})
  const [sifre, setSifre] = React.useState({value:'', hata:false})
  const [yeniSifre, setYeniSifre] = React.useState({value:'', hata:false, mesaj:''})
  const [yeniSifreTekrar, setYeniSifreTekrar] = React.useState({value:'', hata:false, mesaj:''})

const cikis=()=>{
  sessionManager.removeUserSession()
  navigate('/login')
}

const ksDegistir=()=>{
  let durum = true

  if(!kullaniciAdi.value){
    setKullaniciAdi({...kullaniciAdi, hata:true, mesaj:'Doldurulması zorunlu alan.'})
    durum=false
  }
  if(!sifre.value){
    setSifre({...sifre, hata:true})
    durum=false
  }
  if(!yeniSifre.value){
    setYeniSifre({...yeniSifre, hata:true, mesaj:'Doldurulması zorunlu alan.'})
    durum=false
  }
  if(!yeniSifreTekrar.value){
    setYeniSifreTekrar({...yeniSifreTekrar, hata:true, mesaj:'Doldurulması zorunlu alan.'})
    durum=false
  }
  
  if(durum){
    if(yeniSifre.value === yeniSifreTekrar.value){
      const data={
        kullaniciAdi:kullaniciAdi.value.toString(),
        sifre:sifre.value.toString(),
        yeniSifre:yeniSifre.value.toString()
      }
      setLoading(true)
      const toastId=toast.loading('Kullanıcı bilgileri güncelleniyor.')

      services.postAxios('Kullanici/UpdateKullanici', data).then((res)=>{
        setLoading(false)
        toast.success('Kullanıcı biligleri güncellendi.',{duration:2500, id:toastId})
        Sifirla()
        setSdModal(false)
        cikis()
      }).catch((err)=>{
        toast.error(typeof err?.response?.data =='string' ? err.response.data || 
        'Bir hata oluştu.': 'Bir hata oluştu',{duration:3000, id:toastId})
        setLoading(false)
      })
    }else{
      setYeniSifreTekrar({...yeniSifreTekrar, hata:true, mesaj:'Şifreler uyuşmuyor.'})
      setYeniSifre({...yeniSifre, hata:true, mesaj:'Şifreler uyuşmuyor.'})
    }
  }
}

function GetKullanici(){
  services.getAxios('Kullanici/GetKullaniciAdi').then((res)=>{
    setKullaniciAdi({...kullaniciAdi, value:res.data})
    setBackdrop(false);
  }).catch((err)=>{
    toast.error('Bir hata oluştu.',{duration:3000})
    setBackdrop(false);
    setSdModal(false);
  })
}

function Sifirla(){
  setKullaniciAdi({value:'', hata:false, mesaj:''})
  setSifre({value:'', hata:false})
  setYeniSifre({value:'', hata:false, mesaj:''})
  setYeniSifreTekrar({value:'', hata:false, mesaj:''})
  setLoading(false)
}

  return (
    <>
      <Toaster
      position="top-right"
      reverseOrder={false}
     />
    <AppBar position="fixed" style={{backgroundColor:'#fff', boxShadow:'0px 1px 1px -1px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 1px 10px 0px rgba(0,0,0,0.12)'}}>
      <Container maxWidth="xl" className="text-black">
        <Toolbar disableGutters className="justify-content-between" style={{minHeight:"60px"}}>
          <Typography
            variant="h6"
            noWrap
            component="a"
            href="/"
            sx={{
              mr: 1,
              //display:  {xs: 'none', sm: 'flex'},
              fontFamily: 'monospace',
              fontWeight: 700,
              letterSpacing: '.3rem',
              color: 'inherit',
              textDecoration: 'none',
            }}
          >
            <img src={logo} width={150}/>
          </Typography>
          <Box className='flex-grow-0'>
            <Tooltip title="Oturumu Kapat">
              <IconButton aria-label="logout" className="me-2" onClick={cikis}>
                <LogoutIcon className="" />
              </IconButton>
            </Tooltip>
            <Tooltip title="Kullanıcı bilgilerini değiştir">
              <IconButton onClick={()=>{setSdModal(true); GetKullanici(); setBackdrop(true)}} sx={{ p: 0 }}>
                <Avatar sx={{ width: 32, height: 32 }} alt="Remy Sharp">
                  <PersonIcon/>
                </Avatar>
              </IconButton>
            </Tooltip>
          </Box>
        </Toolbar>
      </Container>
    </AppBar>
         <Container style={{paddingTop:'100px'}}>
             <Outlet/>
         </Container>
         <div>
            <Dialog open={sdModal}
            fullWidth={true}
            maxWidth={"xs"}>
               <Backdrop
                  sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                  open={backdrop}
                >
                  <CircularProgress color="inherit" />
                </Backdrop>
                <DialogTitle>Kullanıcı Bilgilerini Düzenle</DialogTitle>
                <DialogContent>
                <TextField
                    required className="mb-2"
                    error={kullaniciAdi.hata}
                    onChange={(event)=> setKullaniciAdi({...kullaniciAdi,value:event.target.value})}
                    margin="dense"
                    id="kullaniciAdi"
                    value={kullaniciAdi.value}
                    label="Kullanici Adı"
                    type="text"
                    fullWidth
                    size="small"
                    variant="standard"
                    helperText={kullaniciAdi.mesaj}
                />
                <TextField
                    required className="mb-2"
                    error={sifre.hata}
                    onChange={(event)=> setSifre({...sifre,value:event.target.value, hata:false})}
                    margin="dense"
                    id="sifre"
                    value={sifre.value}
                    label="Eski şifre"
                    type="password"
                    fullWidth
                    size="small"
                    variant="standard"
                    helperText={sifre.hata ? 'Doldurulması zorunlu alan.': ''}
                />
                <TextField
                    required className="mb-2"
                    error={yeniSifre.hata}
                    onChange={(event)=> setYeniSifre({...yeniSifre,value:event.target.value, hata:false})}
                    margin="dense"
                    id="yeniSifre"
                    value={yeniSifre.value}
                    label="Yeni şifre"
                    type="password"
                    fullWidth
                    size="small"
                    variant="standard"
                    helperText={yeniSifre.hata? yeniSifre.mesaj:''}
                />
                <TextField
                    required
                    error={yeniSifreTekrar.hata}
                    onChange={(event)=> setYeniSifreTekrar({...yeniSifreTekrar,value:event.target.value, hata:false})}
                    margin="dense"
                    id="yeniSifreTekrar"
                    value={yeniSifreTekrar.value}
                    label="Yeni şifre tekrar"
                    type="password"
                    fullWidth
                    size="small"
                    variant="standard"
                    helperText={yeniSifreTekrar.hata? yeniSifreTekrar.mesaj:''}
                />
                </DialogContent>
                <DialogActions>
                <Button onClick={()=>{setSdModal(false); Sifirla()}}>İptal</Button>
                <LoadingButton onClick={ksDegistir} loading={loading} color='error'>
                  <span>Kaydet</span>
                </LoadingButton>
                </DialogActions>
            </Dialog>
           
            </div>
    </>
  );
}
export default ResponsiveAppBar;