import React from "react";
import request from "../services";
import { toast } from "react-hot-toast";
import Table from "../components/Table"
import HesapEkle from "../components/HesapEkle"
import LoadingButton from '@mui/lab/LoadingButton';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';

import {InputLabel, MenuItem, FormControl, Select, 
    Button, Dialog, DialogActions, DialogContent, 
    DialogContentText, DialogTitle,TextField} from '@mui/material';

const Hesaplar =()=>{
    const [musteri, setMusteri] = React.useState({value:'', name: ''});
    const [musteriEkleLoading, setMusteriEkleLoading] = React.useState(false);
    const [musteriSilLoading, setMusteriSilLoading] = React.useState(false);
    const [musteriSilDisabled, setMusteriSilDisabled] = React.useState(true);
    const [msModal, setMsModal] = React.useState(false);
    const [meModal, setMeModal] = React.useState(false);
    const [heModal, setHeModal] = React.useState(false);
    const [musteriInput, setMusteriInput] = React.useState({name:'', hata:false, mesaj:''});
    const [musteriler, setMusteriler] = React.useState([]);
    const [yeniHesap, setYeniHesap] = React.useState(false);

    React.useEffect(()=>{
        GetMusteri()
    },[])
    
    const GetMusteri=()=>{
        request.getAxios('Musteri/GetMusteriler').then((res)=>{
            setMusteriler(res.data)
        }).catch((err)=>{
            if(err?.response?.status === 401){
                window.location.href = '/login'
                return;
            }
            toast.error('Müşteriler yüklenirken bir hata oluştu.', {duration:3000})
        })
    }

    const musteriSil = () => {
        const toastId = toast.loading(musteri.name + ' Siliniyor lütfen bekleyin.')
        request.postAxios('Musteri/DeleteMusteri?id=' + musteri.value).then((res)=>{
            setMusteriler([...musteriler.filter((item)=> item.id!==musteri.value)])
            setMusteri({value:'',name:''})
            setMusteriSilDisabled(true)
            setMusteriSilLoading(false);
            toast.success(musteri.name + " Başarılı bir şekilde silindi.", 
            {id:toastId, duration:2500})
        }).catch((err)=>{
            if(err?.response?.status === 401){
                window.location.href = '/login'
                return;
            }
            setMusteriSilLoading(false);
            toast.error(musteri.name + " Silinirken bir hata oluştu.", 
            {id:toastId, duration:2500})
        })
    };
    
    const musteriChange = (event)=>{
        setMusteri({value:event.target.value, 
            name:musteriler.find((item)=> item.id===event.target.value).ad});
        setMusteriSilDisabled(false);
    };

    const musteriEkle=()=>{
        if(musteriInput.name){
            setMeModal(false);
            setMusteriEkleLoading(true);
            const toastId = toast.loading('İşlem tamamlanıyor lütfen bekleyiniz!')

            request.postAxios('Musteri/InsertMusteri?ad=' + musteriInput.name).then((res)=>{
                GetMusteri()
                setMusteriEkleLoading(false)
                toast.success(musteriInput.name + ' Müşteriler arasına eklendi.', 
                {id:toastId, duration:2500})
                setMusteriInput({name:'', hata:false, mesaj:''})
            }).catch((err)=>{
                if(err?.response?.status === 401){
                    window.location.href = '/login'
                    return;
                }
                setMusteriEkleLoading(false)
                toast.error('Müşteri eklenirken bir hata oluştu.', 
                {id:toastId, duration:2500})
                setMeModal(true);
            })
        }else{
            setMusteriInput({...musteriInput, hata:true, mesaj:'Doldurulması zorunlu alan.'})
        }
    }

    

    return (
        <>
        <div className="row">
            <div className="col-md-4 col-sm-12 mb-3">
                
                    <FormControl fullWidth size="small">
                            <InputLabel id="demo-simple-select-label">Müşteriler</InputLabel>
                            <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={musteri.value}
                            label="Müşteriler"
                            onChange={musteriChange}
                            >
                                {musteriler.map((item, index)=>(
                                    <MenuItem value={item.id} key={index}>{item.ad}</MenuItem>
                                ))}
                            </Select>
                    </FormControl>
            </div>
            <div className="col-md-8 col-sm-12 mb-3 d-md-flex d-grid justify-content-md-between">
                <div className="d-md-flex d-grid">
                    <LoadingButton 
                    className="me-md-2 mb-3 mb-md-0 text-capitalize" 
                    //variant="outlined" 
                    endIcon={<AddIcon />}
                    loadingPosition="end"
                    loading={musteriEkleLoading}
                    onClick={()=>setMeModal(true)}>
                        <span>Müşteri Ekle</span>
                    </LoadingButton>
                    <LoadingButton 
                    className="mb-3 mb-md-0 text-capitalize" 
                    color='error' 
                    //variant="outlined" 
                    endIcon={<DeleteIcon />}
                    loadingPosition="end"
                    loading={musteriSilLoading}
                    disabled={musteriSilDisabled}
                    onClick={()=>setMsModal(true)}>
                        <span>Müşteri Sil</span>
                    </LoadingButton>
                </div>
                <Button 
                className="text-capitalize" 
                //variant="outlined" 
                endIcon={<AddIcon />}
                disabled={musteriSilDisabled}
                onClick={()=>setHeModal(true)}>
                    Hesap Ekle
                </Button>
            </div>
        </div>
            <Table musteriId={musteri.value} yeniHesap={yeniHesap}/>
            <div>
                <Dialog
                    open={msModal}
                    onClose={()=>setMsModal(false)}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogTitle id="alert-dialog-title">
                    {musteri.name + " Silinecek"}
                    </DialogTitle>
                    <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        {musteri.name + ' Altında bulunan tüm hesaplar silinecek.'+
                        ' Yine de silmek istiyor musunuz?'}
                    </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                    <Button onClick={()=>{setMsModal(false)}}>İptal</Button>
                    <Button onClick={()=>{setMusteriSilLoading(true); setMsModal(false); musteriSil()}} autoFocus color='error'>
                         SİL
                    </Button>
                    </DialogActions>
                </Dialog>
            </div>
            <div>
            <Dialog open={meModal}
            fullWidth={true}
            maxWidth={"xs"}>
                <DialogTitle>Müşteri Ekle</DialogTitle>
                <DialogContent>
                <TextField
                    required
                    error={musteriInput.hata}
                    onChange={(event)=> setMusteriInput({...musteriInput,name:event.target.value})}
                    margin="dense"
                    id="name"
                    value={musteriInput.name}
                    label="Ünvan"
                    type="text"
                    fullWidth
                    variant="standard"
                    helperText={musteriInput.mesaj}
                />
                </DialogContent>
                <DialogActions>
                <Button onClick={()=>{setMeModal(false); 
                    setMusteriInput({name:'', hata:false, mesaj:''})}}>İptal</Button>
                <Button onClick={musteriEkle} color='error'>Kaydet</Button>
                </DialogActions>
            </Dialog>
            </div>
            <div>
            <Dialog open={heModal}
            fullWidth={true}
            maxWidth={"md"}>
                <DialogTitle>Hesap Ekle</DialogTitle>
                <DialogContent>
                    <HesapEkle setModal={(x)=> setHeModal(x)} musteriId={musteri.value} 
                    yeniHesap={()=>setYeniHesap(!yeniHesap)}/>
                </DialogContent>
                {/* <DialogActions>
                <Button onClick={()=>{setHeModal(false); }}>İptal</Button>
                <Button onClick={musteriEkle} color='error'>Kaydet</Button>
                </DialogActions> */}
            </Dialog>
            </div>
        </>
    );
}

export default Hesaplar;