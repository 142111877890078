import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import {Tab, Tabs, Typography, Box} from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';
import SaveIcon from '@mui/icons-material/Save';
import HesapEkle from '../components/HesapEkle';
import { toast } from 'react-hot-toast';
import TableAlici from '../components/TableAlici';
import { useParams } from 'react-router-dom';
import request from '../services';
import TextField from '@mui/material/TextField';
import Chip from '@mui/material/Chip';
import { MuiFileInput } from 'mui-file-input'

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `vertical-tab-${index}`,
    'aria-controls': `vertical-tabpanel-${index}`,
  };
}

const HesapDuzenle =()=>{
    const [value, setValue] = React.useState(0);
    const [konu, setKonu] = useState({value:'', hata:false})
    const [id, setId] = useState()
    const [loading, setLoading] = useState(false)
    const [aliciLoading, setAliciLoading] = useState(false)
    const [alici, setAlici] = useState({value:'', hata:false})
    const [yeniAlici, setYeniAlici] = React.useState(false);
    const [attachment, setAttachment] = React.useState([]);
    const [textArea, setTextArea] = React.useState({value:'', hata:false});
    const [file, setFile] = React.useState(null);
    let { accountId } = useParams();

    const handleChange = (event, newValue) => {
      setValue(newValue);
    };

    const kaydet = ()=>{
      let durum = true;

      if(!konu.value){
        setKonu({...konu, hata:true})
        durum=false;
      }
      if(!textArea.value){
        setTextArea({...textArea, hata:true})
        durum=false;
      }

      if(durum){
        setLoading(true);
        const toastId = toast.loading('Posta kayıt ediliyor lütfen bekleyiniz.')
        const formData = new FormData();
        formData.append("id", id || 0)
        formData.append("hesapId", parseInt(accountId))
        formData.append("konu", konu.value)
        formData.append("text", textArea.value)
        file?.map((item)=>{
          formData.append("dosyalar", item)
        })
        
        request.postAxios('Posta/InsertUpdatePosta', formData).then((res)=>{
          setFile(null)
          GetPosta()
          toast.success("Posta kayıt edildi.", 
          {id:toastId, duration:3000})
          setLoading(false)
        }).catch((err)=>{
          if(err?.response?.status === 401){
            window.location.href = '/login'
            return;
        }
        const hata ="Posta kayıt edilirken bir hata oluştu."
          toast.error(typeof err?.response?.data =='string' ? err.response.data || hata:hata, 
          {id:toastId, duration:3000})
          setLoading(false)
        })
      }
    }

    const aliciKaydet=()=>{
      if(alici.value){
        setAliciLoading(true);
            const toastId = toast.loading('Alıcılar kayıt ediliyor lütfen bekleyiniz.')
            const data={alici: alici.value}
            
            request.postAxios('Alici/InsertAlici?hesapId='+accountId, data).then((res)=>{
              toast.success("Alıcılar kayıt edildi.", 
              {id:toastId, duration:2500})
              setAliciLoading(false)
              setAlici({value:'', hata:false})
              setYeniAlici(!yeniAlici)
            }).catch((err)=>{
              if(err?.response?.status === 401){
                window.location.href = '/login'
                return;
            }
              toast.error("Alıcılar kayıt edilirken bir hata oluştu.", 
              {id:toastId, duration:3000})           
              setAliciLoading(false)
            })
      }else{
        setAlici({...alici, hata:true})
      }
    }

    const fileAdd=(value, info)=>{
      let size = 0;
      value.map((item)=> {
        size += item.size
      })
      attachment?.map((item)=>{
        size += item.boyut
      })
      
      if(size > 26214400)
      toast.error("Toplam dosya boyutu 25 MB'yi geçemez", {duration:3000})
      else
      setFile(value)
    }

    const fileText=(value)=>{
      return value.length > 1 ? value.length + ' dosya seçildi' : value[0].name
    }

    const deleteAttachment=(id, ad) => {
      const toastId= toast.loading(ad + ' siliniyor.')
      request.postAxios('Posta/DeletePostaEk?id='+ id).then((res)=>{
        toast.success(ad + ' silindi.', {duration:2500, id:toastId})
        setAttachment([...attachment.filter((item)=> item.id !== id)]);
      }).catch((err)=>{
        toast.success(typeof err?.response?.data =='string' ? err.response.data||
          ad + ' silinirken bir hata oluştu.': ad+' silinirken bir hata oluştu', {duration:3000, id:toastId})
      })
    }

    function GetPosta(){
        request.getAxios('Posta/GetPosta?hesapId='+accountId, {'Content-Type': 'multipart/form-data'}).then((res)=>{
          setId(res.data.id)
          setKonu({value:res.data.konu || '', hata:false})
          setTextArea({value:res.data.text || '', hata:false})
          setAttachment(res.data.mevcutDosyalar)
        }).catch((err)=>{
          if(err?.response?.status === 401){
            window.location.href = '/login'
            return;
        }
          toast.error("Posta yüklenirken bir hata oluştu.", 
                  {duration:3000})
        })
    }

    useEffect(()=>{
      if(value === 1){
        GetPosta()
      }
    },[value, accountId])

    return(
        <Box>
        <Tabs
            //orientation="vertical"
            variant="standard"
            value={value}
            onChange={handleChange}
            aria-label="Vertical tabs example"
            //sx={{ borderRight: 1, borderColor: 'divider' }}
            //centered
        >
            <Tab label="Hesap" {...a11yProps(0)} />
            <Tab label="Posta" {...a11yProps(1)} />
            <Tab label="Alıcı" {...a11yProps(2)} />
        </Tabs>
        <TabPanel value={value} index={0}>
            <HesapEkle hesapId={accountId}/>
        </TabPanel>
        <TabPanel value={value} index={1}>
          <div>
            <div className='row'>
                <div className='col-12 mb-3'>
                <TextField
                      required error={konu.hata}
                      onChange={(event)=>{
                        setKonu({value:event.target.value, hata:event.target.value? false: true})} }
                      margin="dense"
                      id="konu"
                      value={konu.value}
                      label="Konu"
                      type="text"
                      fullWidth
                      size="small"
                      helperText={konu.hata ? 'Doldurulması zorunlu alan.' : ''}
                      />
                </div>
            </div>
            <div className='row mb-3'>
              <div className='d-flex mb-2 align-items-center'>
              </div>
              <div className='col-12'>
              <TextField
                required error={textArea.hata}
                className='w-100'
                id="outlined-multiline-flexible"
                label="İleti"
                multiline
                rows={20}
                value={textArea.value}
                onChange={(event)=> setTextArea({value:event.target.value, hata:event.target.value? false: true})}
                helperText={textArea.hata ? 'Doldurulması zorunlu alan.' : ''}
              />
              </div>
            </div>
            <div className='row'>
                <div className='col-12 mb-2'>
                  <MuiFileInput placeholder='Dosya ekle' inputProps={{ 
                    accept: '.png, .jpeg, .doc, .docx, .jpg, .mp3, .mp4, .pdf, .ppt, .pptx, .rar, .svg, .txt, .xls, .xlsx, .zip' }} 
                  multiple value={file}
                  onChange={fileAdd} getInputText={(value) => value ? fileText(value) : ''}
                  className='w-100'/>
                </div>
            </div>
            <div className='row'>
                <div className='col-12 mb-2'>
                  {attachment?.map((item)=>(
                    <Chip className='m-1' label={item.ad + " (" + (item.boyut / 1048576).toFixed(2) + " MB)"} 
                    onDelete={()=>deleteAttachment(item.id, item.ad)} />
                  ))}
                </div>
            </div>
            <div className='row'>
              <div className='d-flex justify-content-end'>
                <LoadingButton
                loading={loading}
                loadingPosition="end"
                endIcon={<SaveIcon />}
                variant="outlined"
                onClick={kaydet}
                >Kaydet</LoadingButton>
              </div>
            </div>
          </div>
        </TabPanel>
        <TabPanel value={value} index={2}>
            <div className=''>
              <TableAlici hesapId={accountId} yeniAlici={yeniAlici}/>
                  <TextField
                    id="alici" error={alici.hata}
                    label="Alıcılar"
                    multiline placeholder='ornek@exampla.com&#10;ornek@exampla.org'
                    fullWidth className='mt-5'
                    value={alici.value}
                    onChange={(event)=> 
                      setAlici({hata: event.target.value? false: alici.hata, value:event.target.value})}
                    rows={10} helperText={alici.hata ? 'Lütfen alıcı girin..' : ''}/>
                    <div className='mt-2 d-flex justify-content-end'>
                      <LoadingButton
                        loading={aliciLoading}
                        loadingPosition="end"
                        endIcon={<SaveIcon />}
                        variant="outlined"
                        onClick={aliciKaydet}
                        >Kaydet</LoadingButton>
                    </div>
            </div>
        </TabPanel>
        </Box>
    )
}

export default HesapDuzenle;